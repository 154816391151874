import {useRef, useEffect, useState, useMemo} from 'react';
import FocusTrap from 'focus-trap-react';
import {useTranslation} from 'react-i18next';
import {cnb} from 'cnbuilder';
import mixpanel from 'mixpanel-browser';
import {useSelector, useDispatch} from 'react-redux';
import {appUpdate} from '@teladoc/fe-ccm/ui/app/app-actions';
import Link from '@teladoc/fe-ccm/ui/router/Link';
import some from 'lodash/some';
import Popover from '@teladoc/pulse/ui/Popover';
import Button from '@teladoc/pulse/ui/Button';
import IconProfileDefault from '@teladoc/pulse/icons/profile-default.svg';
import IconChatDefault from '@teladoc/pulse/icons/chat-default.svg';
import LanguageSwitcher from '@teladoc/fe-ccm/ui/common/language-switcher';
import KeyHandler from '@teladoc/fe-ccm/ui/common/utilities/key-handler';
import {useLoadedState} from '@teladoc/fe-ccm/ui/common/use-loaded-state';
import IconMessageUnreadDefault from '@/icons/message-unread-default.svg';
import {getPrimaryRouteById, accountRoutes, logoutRoute} from '@/router/routes';
import {
    BREAKPOINT_SIZE,
    NAV_MENU,
    FOOTER_CONTENT_STATE,
    EVENT_PROPS,
} from '@/config';
import SubNavigation from '@/Navigation/sub-navigation/SubNavigation';
import CommonUtils from '@/utils/common-utils';
import {useMessageCenterRoutes} from '@/utils/use-message-center-routes';
import PIWIKUtils from '@/utils/piwik-utils';
import css from './navigation-menu.scss';

const NavigationMenu = () => {
    const menuBar = useRef(null);
    const accountBar = useRef(null);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {breakpoint} = useSelector(state => state.app);
    const {hasUnread} = useSelector(state => state.chat);
    const {programs, userId} = useSelector(state => state.user);
    const {
        hasCareTeam,
        hasTelemed,
        oneAppActiveNav,
        hasMentalHealth,
        hasChatAvailable,
        hasMHGuideAvailable,
        hasConditionManagement,
        hasPartnerPrograms,
        branding,
    } = useSelector(state => state.app);
    const isBrandingLoaded = useLoadedState(branding);
    const [updatedProgramRoutes, setUpdatedProgramRoutes] = useState([]);
    const {getActiveRoutesAsString} = useMessageCenterRoutes();
    const mhAvailable = hasMentalHealth && hasMHGuideAvailable;
    const cmAvailable = hasConditionManagement && hasChatAvailable;
    const isMessageAvailable = mhAvailable || cmAvailable || hasCareTeam;
    const isRestrictedMember = useMemo(() => {
        return branding?.restricted_member_correctional_facilities;
    }, [branding]);

    const resetShowSidebar = (id = NAV_MENU.Home.id) => {
        dispatch(appUpdate({oneAppActiveNav: id}));
        dispatch(
            appUpdate({
                oneAppActiveProgram: FOOTER_CONTENT_STATE.TeleMed.id,
            })
        );
    };

    const onDashboardClick = () => {
        resetShowSidebar();
        mixpanel.track('navigation.button.clicked', {
            level: 1,
            button: 'Home',
        });

        CommonUtils.setTrackingData(EVENT_PROPS.Global, 1, EVENT_PROPS.Home);
    };

    const onProgramsToggle = isShowing => {
        if (isShowing) {
            mixpanel.track('navigation.button.clicked', {
                level: 1,
                button: 'Programs',
            });

            PIWIKUtils.buildTrackingEvent({
                eventAction: 'Header Navigation Clicked',
                eventName: 'Programs Menu',
                userId,
            });

            CommonUtils.setTrackingData(
                EVENT_PROPS.Global,
                1,
                EVENT_PROPS.Programs
            );
        }
    };

    const onHealthInfoToggle = isShowing => {
        if (isShowing) {
            mixpanel.track('navigation.button.clicked', {
                level: 1,
                button: 'Health Info',
            });

            CommonUtils.setTrackingData(
                EVENT_PROPS.Global,
                1,
                EVENT_PROPS.HealthInfo
            );
        }
    };

    const onAccountToggle = isShowing => {
        if (isShowing) {
            mixpanel.track('navigation.button.clicked', {
                level: 1,
                button: 'Account',
            });

            CommonUtils.setTrackingData(
                EVENT_PROPS.Global,
                1,
                EVENT_PROPS.Account
            );
        }
    };

    const updateSidebarState = id => {
        dispatch(appUpdate({oneAppActiveNav: NAV_MENU.Programs.id}));

        if (id === 'conditionManagement') {
            CommonUtils.updateSidebar(true, dispatch);
            dispatch(
                appUpdate({
                    oneAppActiveProgram:
                        FOOTER_CONTENT_STATE.ConditionManagement.id,
                })
            );
        } else if (id === 'mentalHealth') {
            dispatch(
                appUpdate({
                    oneAppActiveProgram: FOOTER_CONTENT_STATE.MyStrength.id,
                })
            );
        } else if (id === 'partnerPrograms') {
            dispatch(
                appUpdate({
                    oneAppActiveProgram:
                        FOOTER_CONTENT_STATE.PartnerPrograms.id,
                })
            );
        } else {
            CommonUtils.updateSidebar(false, dispatch);
            dispatch(
                appUpdate({
                    oneAppActiveProgram: FOOTER_CONTENT_STATE.TeleMed.id,
                })
            );
        }
    };

    const onProgramsMenuClick = id => {
        PIWIKUtils.buildTrackingEvent({
            eventAction: 'Header Navigation Clicked',
            eventName: id,
            userId,
        });

        updateSidebarState(id);
    };

    const onMessagesOpen = () => {
        CommonUtils.updateSidebar(false, dispatch);
        dispatch(appUpdate({oneAppActiveNav: NAV_MENU.Message.id}));
        mixpanel.track('navigation.button.clicked', {
            level: 2,
            button: 'Messages',
        });

        CommonUtils.setTrackingData(
            EVENT_PROPS.Global,
            1,
            EVENT_PROPS.Messages,
            {[EVENT_PROPS.Inbox]: getActiveRoutesAsString()}
        );
    };

    const setCurrentState = evt => {
        if (!evt.activeState) {
            const pathname = document?.location?.pathname;

            if (pathname?.includes('message-center')) {
                dispatch(
                    appUpdate({oneAppActiveNav: NAV_MENU.MessageCenter.id})
                );
            } else if (
                pathname?.includes('condition-management') ||
                pathname?.includes('mental-health') ||
                pathname?.includes('/partner_programs')
            ) {
                dispatch(appUpdate({oneAppActiveNav: NAV_MENU.Programs.id}));
            } else if (
                pathname?.includes('/account') ||
                pathname?.includes('/help')
            ) {
                dispatch(appUpdate({oneAppActiveNav: NAV_MENU.Account.id}));
            } else if (
                pathname?.includes('/health-info') ||
                pathname?.includes('/consultation_history')
            ) {
                dispatch(appUpdate({oneAppActiveNav: NAV_MENU.HealthInfo.id}));
            } else if (pathname?.includes('message')) {
                dispatch(appUpdate({oneAppActiveNav: NAV_MENU.Message.id}));
            } else if (pathname?.includes('/sitemap')) {
                dispatch(appUpdate({oneAppActiveNav: NAV_MENU.Sitemap.id}));
            } else {
                dispatch(appUpdate({oneAppActiveNav: NAV_MENU.Home.id}));
            }
            evt.activeState = true;
        }
    };

    // event to Handle Browser Back & Forward button
    window.addEventListener('popstate', setCurrentState);

    useEffect(() => {
        setUpdatedProgramRoutes(
            CommonUtils.updateProgramsMenuRoutes({
                programs,
                appState: {
                    hasConditionManagement,
                    hasMentalHealth,
                    hasPartnerPrograms,
                },
            })
        );
    }, [programs, hasConditionManagement, hasMentalHealth, hasPartnerPrograms]);

    return (
        <nav data-testid="header-nav-menu" className={css.navContainer}>
            <div className={css.menuItemsLeft}>
                <ul ref={menuBar} className={css.navItemsList} role="menubar">
                    <li role="none">
                        <Link
                            to={getPrimaryRouteById(NAV_MENU.Home.id).path}
                            className={cnb(css.home, {
                                [css.navActiveMenu]:
                                    oneAppActiveNav === NAV_MENU.Home.id,
                            })}
                            name="oneapp.home"
                            activeClassName={css.navActiveMenu}
                            aria-label={t('oneapp.home')}
                            tabIndex={0}
                            onClick={onDashboardClick}
                            role="menuitem"
                            onKeyDown={e =>
                                KeyHandler.keyDown(e, menuBar?.current)
                            }
                        >
                            {t('oneapp.home')}
                        </Link>
                    </li>
                    {CommonUtils.showProgramsMenu({
                        hasConditionManagement,
                        hasMentalHealth,
                        hasPartnerPrograms,
                    }) && (
                        <li className={css.menuItemList} role="none">
                            <Popover
                                hideOnContentClick
                                hideOnOutsideClick
                                onToggle={onProgramsToggle}
                                trigger={
                                    <Button
                                        className={cnb(css.subNavMenu, {
                                            [css.navActiveMenu]:
                                                oneAppActiveNav ===
                                                NAV_MENU.Programs.id,
                                        })}
                                        name="oneapp.programs"
                                        aria-label={t('oneapp.programs')}
                                        role="menuitem"
                                        onKeyDown={e =>
                                            KeyHandler.keyDown(
                                                e,
                                                menuBar?.current
                                            )
                                        }
                                    >
                                        <span className={css.navItemText}>
                                            {t('oneapp.programs')}
                                        </span>
                                    </Button>
                                }
                                content={
                                    <FocusTrap
                                        focusTrapOptions={{
                                            clickOutsideDeactivates: true,
                                        }}
                                    >
                                        <div>
                                            <SubNavigation
                                                className={
                                                    css.memberDropdownLeft
                                                }
                                                itemClassName={css.dropdownItem}
                                                activeItemClassName={
                                                    css.dropdownActiveItem
                                                }
                                                tabName={NAV_MENU.Programs.id}
                                                activeRoute={
                                                    updatedProgramRoutes
                                                }
                                                onItemClick={
                                                    onProgramsMenuClick
                                                }
                                            />
                                        </div>
                                    </FocusTrap>
                                }
                            />
                        </li>
                    )}
                    {!branding?.supress_health_info && (
                        <li className={css.menuItemList} role="none">
                            <Popover
                                hideOnContentClick
                                hideOnOutsideClick
                                onToggle={onHealthInfoToggle}
                                trigger={
                                    <Button
                                        className={cnb(css.subNavMenu, {
                                            [css.navActiveMenu]:
                                                oneAppActiveNav ===
                                                NAV_MENU.HealthInfo.id,
                                        })}
                                        role="menuitem"
                                        name="oneapp.healthInfo"
                                        aria-label={t('oneapp.healthInfo')}
                                        onKeyDown={e =>
                                            KeyHandler.keyDown(
                                                e,
                                                menuBar?.current
                                            )
                                        }
                                    >
                                        <span className={css.navItemText}>
                                            {t('oneapp.healthInfo')}
                                        </span>
                                    </Button>
                                }
                                content={
                                    <FocusTrap
                                        focusTrapOptions={{
                                            clickOutsideDeactivates: true,
                                        }}
                                    >
                                        <div>
                                            <SubNavigation
                                                className={`${css.memberDropdownLeftHealthInfo} ${css.menuLeftDropdownHeight}`}
                                                itemClassName={css.dropdownItem}
                                                activeItemClassName={
                                                    css.dropdownActiveItem
                                                }
                                                tabName={NAV_MENU.HealthInfo.id}
                                                activeRoute={CommonUtils.updateHealthInfoMenuRoute(
                                                    {hasTelemed, branding}
                                                )}
                                                onItemClick={() =>
                                                    resetShowSidebar(
                                                        NAV_MENU.HealthInfo.id
                                                    )
                                                }
                                            />
                                        </div>
                                    </FocusTrap>
                                }
                            />
                        </li>
                    )}
                </ul>
            </div>
            <div className={css.menuItemsRight}>
                <ul
                    ref={accountBar}
                    className={css.navItemsList}
                    role="menubar"
                >
                    {isMessageAvailable && (
                        <li className={cnb(css.menuItemList)} role="none">
                            <Link
                                className={cnb(
                                    css.subNavMenu,
                                    css.menuTextColor,
                                    {
                                        [css.navActiveMenu]:
                                            oneAppActiveNav ===
                                            NAV_MENU.MessageCenter.id,
                                    }
                                )}
                                activeClassName={css.navActiveMenu}
                                role="menuitem"
                                name="oneapp.messages"
                                aria-label={t('oneapp.messages')}
                                onClick={onMessagesOpen}
                                to={
                                    getPrimaryRouteById(
                                        NAV_MENU.MessageCenter.id
                                    ).path
                                }
                                onKeyDown={e =>
                                    KeyHandler.keyDown(e, accountBar?.current)
                                }
                            >
                                <span className={css.navItemText}>
                                    {some(hasUnread) ? (
                                        <IconMessageUnreadDefault
                                            className={css.navIconLarge}
                                        />
                                    ) : (
                                        <IconChatDefault
                                            className={css.navIconLarge}
                                        />
                                    )}
                                    <span className={css.navText}>
                                        {t('oneapp.messages')}
                                    </span>
                                </span>
                            </Link>
                        </li>
                    )}
                    {isBrandingLoaded && !isRestrictedMember && (
                        <li className={css.menuItemList} role="none">
                            <Popover
                                hideOnContentClick
                                hideOnOutsideClick
                                onToggle={onAccountToggle}
                                trigger={
                                    <Button
                                        className={cnb(
                                            css.subNavMenu,
                                            css.menuTextColor,
                                            {
                                                [css.navActiveMenu]:
                                                    oneAppActiveNav ===
                                                    NAV_MENU.Account.id,
                                            }
                                        )}
                                        role="menuitem"
                                        name="oneapp.account"
                                        aria-label={t('oneapp.account')}
                                        onKeyDown={e =>
                                            KeyHandler.keyDown(
                                                e,
                                                accountBar?.current
                                            )
                                        }
                                    >
                                        <span className={css.navItemText}>
                                            <IconProfileDefault
                                                className={css.navIconLarge}
                                            />
                                            <span className={css.navText}>
                                                {t('oneapp.account')}
                                            </span>
                                        </span>
                                    </Button>
                                }
                                content={
                                    <FocusTrap
                                        focusTrapOptions={{
                                            clickOutsideDeactivates: true,
                                        }}
                                    >
                                        <div>
                                            <SubNavigation
                                                className={`${css.memberDropdown} ${css.menuRightDropdownHeight}`}
                                                itemClassName={css.dropdownItem}
                                                activeItemClassName={
                                                    css.dropdownActiveItem
                                                }
                                                onItemClick={() =>
                                                    resetShowSidebar(
                                                        NAV_MENU.Account.id
                                                    )
                                                }
                                                tabName={NAV_MENU.Account.id}
                                                activeRoute={[
                                                    ...CommonUtils.filterRoutesByBrandSuppressedRoutes(
                                                        accountRoutes,
                                                        branding
                                                    ),
                                                    ...logoutRoute,
                                                ]}
                                            />
                                        </div>
                                    </FocusTrap>
                                }
                            />
                        </li>
                    )}
                    {breakpoint === BREAKPOINT_SIZE.Large && (
                        <li role="none" className={cnb(css.menuItemList)}>
                            <LanguageSwitcher
                                trackingData={CommonUtils.setTrackingData}
                                keyDownPress={e =>
                                    KeyHandler.keyDown(e, accountBar?.current)
                                }
                            />
                        </li>
                    )}
                </ul>
            </div>
        </nav>
    );
};

export default NavigationMenu;
