import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {cnb} from 'cnbuilder';
import camelCase from 'lodash/camelCase';
import some from 'lodash/some';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import Hamburger from '@teladoc/fe-ccm/ui/common/hamburger/Hamburger';
import useFavicon from '@teladoc/fe-ccm/ui/common/use-favicon';
import useUpdateThemeColors from '@teladoc/fe-ccm/ui/common/use-theme-update';
import useMetaTags from '@teladoc/fe-ccm/ui/common/use-meta-tags';
import Link from '@teladoc/fe-ccm/ui/router/Link';
import IconButton from '@teladoc/pulse/ui/IconButton';
import IconArrowLeftDefault from '@teladoc/pulse/icons/arrow-left-default.svg';
import Image from '@teladoc/pulse/ui/Image';
import LanguageSwitcher from '@teladoc/fe-ccm/ui/common/language-switcher';
import Loader from '@teladoc/pulse/ui/Loader';
import StorageUtils from '@livongo/utilities/system/storage';
import useDataTheme from '@/utils/use-data-theme';
import IconMessageUnreadDefault from '@/icons/message-unread-default.svg';
import IconMessageUnreadActive from '@/icons/message-unread-active.svg';
import {getPrimaryRouteById, navRoutes} from '@/router/routes';
import {NAV_MENU, BREAKPOINT_SIZE, EVENT_PROPS, IMPERSONATION} from '@/config';
import CommonUtils from '@/utils/common-utils';
import {useMessageCenterRoutes} from '@/utils/use-message-center-routes';
import NavigationMenu from './navigation-menu';
import SkipTo from './skip-to';
import css from './Header.scss';

const Header = ({
    showMHGuide,
    headerTitle,
    showLeftIcon,
    showHamburger,
    setHeaderTitle,
    setShowMHGuide,
    onSideNavToggle,
    setShowHamburger,
    setIsMobileMenuPage,
    hideMobileTopHeader,
}) => {
    const {t} = useTranslation();
    const {push} = useHistory();
    const homepage = getPrimaryRouteById(NAV_MENU.Home.id);
    const {
        branding,
        breakpoint,
        hasCareTeam,
        isImpersonation,
        isOneAppSidebar,
        hasMentalHealth,
        hasChatAvailable,
        hasPartnerPrograms,
        hasMHGuideAvailable,
        oneAppActiveProgram,
        hasConditionManagement,
    } = useSelector(state => state.app);
    const isRestrictedMember =
        branding?.restricted_member_correctional_facilities;
    const {impersonationInfo} = useSelector(state => state.user);
    const {hasUnread} = useSelector(state => state.chat);
    const [showMobileLanguageSwitcher, setShowMobileLanguageSwitcher] =
        useState((headerTitle && showHamburger) || !headerTitle);
    const [activeLink, setActiveLink] = useState(NAV_MENU.Home.id);
    const [ccmTopHeader, setCCMTopHeader] = useState(false);
    const {getActiveRoutesAsString} = useMessageCenterRoutes();
    const [isBrandingLoaded, setIsBrandingLoaded] = useState(false);
    const [metaTags, setMetaTags] = useState({});
    const [brandName, setBrandName] = useState('Teladoc Health');
    const locationPath = document.location.pathname;
    const hasImpersonationCookie = StorageUtils.get({
        key: IMPERSONATION,
        type: 'session',
        useNative: true,
    });

    const onNavItemClick = (id, event, property, amplitudeProperty) => {
        if (id === NAV_MENU.Home.id) {
            setHeaderTitle('');
            setIsMobileMenuPage(false);
        } else {
            setHeaderTitle(t(`oneapp.${id}`));
            setIsMobileMenuPage(true);
        }
        setActiveLink(id);
        setShowHamburger(false);
        mixpanel.track(event, property);

        let amplitudeProps = {};
        const isMessagesButton =
            amplitudeProperty?.button === EVENT_PROPS.Messages;

        if (isMessagesButton) {
            amplitudeProps = {[EVENT_PROPS.Inbox]: getActiveRoutesAsString()};
        }

        if (amplitudeProperty) {
            CommonUtils.setTrackingData(
                amplitudeProperty?.productLine,
                amplitudeProperty?.level,
                amplitudeProperty?.button,
                amplitudeProps
            );
        }
    };
    const onIconClick = () => {
        push('/condition-management/home');
    };

    useEffect(() => {
        if (breakpoint === BREAKPOINT_SIZE.Small) {
            const pathName = locationPath?.split('/');
            const id = pathName[1] || pathName[2];
            let title = camelCase(id);

            switch (id) {
                case 'home':
                    title = 'home';
                    break;
                case 'program':
                    title = 'programs';
                    break;
                case 'inbox':
                    title = 'messages';
                    break;
                case 'member':
                    title = 'healthInfo';
                    break;
            }

            // handling refresh and browser back button scenario for mobile Header Title
            if (
                title === NAV_MENU.Account.id ||
                title === NAV_MENU.Message.id ||
                title === NAV_MENU.HealthInfo.id ||
                (title !== NAV_MENU.Home.id &&
                    title !== NAV_MENU.HealthInsights.id &&
                    title !== NAV_MENU.HealthInsightsDashboard.id &&
                    title !== NAV_MENU.HealthInsightsReport.id &&
                    title !== NAV_MENU.Sitemap.id &&
                    !pathName[2])
            ) {
                setShowHamburger(false);
                setHeaderTitle(t(`oneapp.${title}`));
                setCCMTopHeader(false);
                setShowMobileLanguageSwitcher(false);

                if (!pathName[2]) {
                    setIsMobileMenuPage(true);
                }
            } else if (title === NAV_MENU.Home.id) {
                setHeaderTitle('');
                setIsMobileMenuPage(false);
                setShowMobileLanguageSwitcher(true);
            } else if (title === 'conditionManagement' && pathName[2]) {
                setCCMTopHeader(true);
                setShowMobileLanguageSwitcher(false);
            } else if (title === NAV_MENU.Sitemap.id) {
                setShowHamburger(false);
                setCCMTopHeader(false);
                setIsMobileMenuPage(false);
                setShowMobileLanguageSwitcher(false);
            } else {
                setCCMTopHeader(false);
                setShowMobileLanguageSwitcher(true);
            }

            if (
                title === NAV_MENU.Account.id ||
                title === NAV_MENU.Message.id ||
                title === NAV_MENU.HealthInfo.id ||
                !pathName[2]
            ) {
                setActiveLink(title);
            } else if (
                title === 'conditionManagement' ||
                title === 'mentalHealth'
            ) {
                setActiveLink('programs');
            } else {
                setActiveLink('');
            }
        } else {
            setActiveLink('');
            setShowMobileLanguageSwitcher(false);
        }
    }, [
        breakpoint,
        locationPath,
        setHeaderTitle,
        setIsMobileMenuPage,
        setShowHamburger,
        t,
    ]);

    const onLogoClick = () => {
        mixpanel.track('navigation.button.clicked', {
            level: 1,
            button: 'logo',
        });

        CommonUtils.setTrackingData(EVENT_PROPS.Global, 1, EVENT_PROPS.Logo);
    };

    useEffect(() => {
        if (branding) {
            if (branding?.brand_code) {
                setIsBrandingLoaded(true);
            }

            if (branding?.brand_name) {
                setBrandName(branding.brand_name);
            }

            if (branding?.meta_tags) {
                setMetaTags({
                    ...branding.meta_tags,
                });
            }
        }
    }, [branding]);

    const isRouteAvailable = id => {
        const routeChecks = {
            [NAV_MENU.Message.id]: () =>
                (hasMentalHealth && hasMHGuideAvailable) ||
                (hasConditionManagement && hasChatAvailable) ||
                hasCareTeam,

            [NAV_MENU.Programs.id]: () =>
                CommonUtils.showProgramsMenu({
                    hasConditionManagement,
                    hasMentalHealth,
                    hasPartnerPrograms,
                }),

            [NAV_MENU.Account.id]: () => !isRestrictedMember,
            [NAV_MENU.HealthInfo.id]: () => !branding?.supress_health_info,
        };

        if (routeChecks[id]) {
            return routeChecks[id]();
        }

        return true;
    };

    const getLogoWidth = () => {
        const isCanadaLife =
            branding?.brand_code === 'clsso' ||
            branding?.brand_code === 'clnonsso';

        return branding?.data_theme !== branding?.brand_code && !isCanadaLife
            ? 180
            : 124;
    };

    useDataTheme();
    useUpdateThemeColors(branding, isBrandingLoaded);
    useMetaTags(brandName, metaTags);
    useFavicon();

    return (
        <header
            className={cnb(css.headerRoot, {
                [css.mobileMentalHealth]: hideMobileTopHeader,
                [css.impersonationHeight]: isImpersonation,
            })}
            data-testid="header"
        >
            {(isImpersonation || hasImpersonationCookie?.impersonatorId) && (
                <div className={css.impersonation}>
                    You are impersonating member{' '}
                    {impersonationInfo?.impersonatedName
                        ?.replace('\\x20', ' ')
                        ?.replace('+', ' ')}
                </div>
            )}
            <SkipTo
                skipToText={t('skip')}
                cssSelector={'*[id="mainContent"]'}
            />
            <div
                className={cnb(css.headerContainer, {
                    [css.mobileMentalHealth]: hideMobileTopHeader,
                })}
            >
                {(showMobileLanguageSwitcher ||
                    breakpoint === BREAKPOINT_SIZE.Large) &&
                    (isBrandingLoaded ? (
                        <Link
                            className={cnb({
                                [css.headerLink]: hideMobileTopHeader,
                                [css.widthForLanguageMenu]:
                                    showMobileLanguageSwitcher,
                                [css.displayForMyS]:
                                    oneAppActiveProgram === 'myStrength',
                            })}
                            to={homepage.path}
                            tabIndex={0}
                            onClick={onLogoClick}
                            role="link"
                        >
                            <Image
                                classNameRoot={cnb(css.logo, {
                                    [css.title]: headerTitle,
                                })}
                                src={CommonUtils.getLogoUrlForBrand({
                                    branding,
                                    width: 180,
                                    height: 40,
                                })}
                                width={getLogoWidth()}
                                height={40}
                                alt={t('oneappLogo', {brandName})}
                            />
                        </Link>
                    ) : (
                        <div className={css.loader}>
                            <Loader inline size="large" />
                        </div>
                    ))}
                {showLeftIcon && (
                    <IconButton
                        className={css.leftArrow}
                        i18nLabel={t('oneapp.leftarrow')}
                        onClick={onIconClick}
                    >
                        <IconArrowLeftDefault />
                    </IconButton>
                )}
                {headerTitle && (
                    <h1
                        className={cnb(css.headerTitle, {
                            [css.headerPosition]: ccmTopHeader,
                            [css.titleAlignment]:
                                isOneAppSidebar || showLeftIcon,
                        })}
                    >
                        {headerTitle}
                    </h1>
                )}
                {showMobileLanguageSwitcher && (
                    <div role="menubar">
                        <LanguageSwitcher mobileView />
                    </div>
                )}
                {showHamburger && (
                    <Hamburger
                        parentClass={css.hamburger}
                        isSidebarShowing={isOneAppSidebar}
                        onClick={onSideNavToggle}
                    />
                )}
                <NavigationMenu
                    showMHGuide={showMHGuide}
                    setShowMHGuide={setShowMHGuide}
                />
                <nav
                    data-testid="header-mobile-nav-menu"
                    className={css.mobileNavContainer}
                >
                    {navRoutes
                        .filter(({onlyWebRoute}) => !onlyWebRoute)
                        .map(
                            ({
                                id,
                                name,
                                path,
                                mixpanelEvent,
                                mixpanelProperty,
                                amplitudeProperty,
                                icon: Icon,
                                activeIcon: ActiveIcon,
                            }) => {
                                const isAvailable = isRouteAvailable(id);

                                return isAvailable ? (
                                    <Link
                                        key={id}
                                        id={id}
                                        role="link"
                                        className={cnb(css.navItem, {
                                            [css.navItemActive]:
                                                id === activeLink,
                                        })}
                                        activeClassName={css.navItemActive}
                                        onClick={() =>
                                            onNavItemClick(
                                                name || id,
                                                mixpanelEvent,
                                                mixpanelProperty,
                                                amplitudeProperty
                                            )
                                        }
                                        to={path}
                                        data-id={id}
                                        aria-label={t(
                                            some(hasUnread) &&
                                                id === NAV_MENU.Message.id
                                                ? 'oneapp.unreadMessages'
                                                : `oneapp.${id}`
                                        )}
                                    >
                                        {id === NAV_MENU.Message.id &&
                                        some(hasUnread) ? (
                                            id === activeLink ? (
                                                <IconMessageUnreadActive
                                                    className={css.navItemIcon}
                                                />
                                            ) : (
                                                <IconMessageUnreadDefault
                                                    className={css.navItemIcon}
                                                />
                                            )
                                        ) : id === activeLink ? (
                                            <ActiveIcon
                                                className={css.navItemIcon}
                                            />
                                        ) : (
                                            <Icon className={css.navItemIcon} />
                                        )}

                                        {t(`oneapp.${id}`)}
                                    </Link>
                                ) : null;
                            }
                        )}
                </nav>
            </div>
        </header>
    );
};

Header.propTypes = {
    headerTitle: PropTypes.string,
    showLeftIcon: PropTypes.bool,
    onSideNavToggle: PropTypes.func,
    showHamburger: PropTypes.bool,
    setHeaderTitle: PropTypes.func,
    setShowHamburger: PropTypes.func,
    setIsMobileMenuPage: PropTypes.func,
    showMHGuide: PropTypes.bool,
    setShowMHGuide: PropTypes.func,
    hideMobileTopHeader: PropTypes.bool,
};

export default Header;
