/* eslint-disable camelcase */

import {cnb} from 'cnbuilder';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import mixpanel from 'mixpanel-browser';
import {appUpdate} from '@teladoc/fe-ccm/ui/app/app-actions';
import IconVideoDefault from '@teladoc/pulse/icons/video-default.svg';
import Link from '@teladoc/fe-ccm/ui/router/Link';
import UserAPI from '@/user/user-oneapp-api';
import {getCommonRouteById} from '@/router/routes';
import {
    CONSENT_AGREEMENT,
    CONSENT_AGREEMENT_URL,
    FOOTER_CONTENT_STATE,
    NAV_MENU,
    SITEMAP,
    VIDEO_URL,
} from '@/config';
import css from './Footer.scss';

const footerLabels = {
    video_url: 'footer.videoCapabilities',
    consent_agreement_url: 'footer.consentAgreement',
    privacy_practices_url: 'footer.privacyPractices',
    terms_of_service_url: 'footer.terms',
    terms_of_use_url: 'footer.termsOfUse',
    terms_of_use: 'footer.termsOfUse',
    non_discrimination_url: 'footer.nonDiscrimination',
    sitemap: 'footer.sitemap',
    legal_disclaimer: 'footer.legal',
    notice_of_privacty_practices: 'footer.noticeOfPrivacyPractices',
    web_and_mobile_privacy_policy: 'footer.webAndMobilePrivacy',
    accessibility: 'footer.accessibility',
};

const Footer = () => {
    const {oneAppActiveProgram, branding, isImpersonation} = useSelector(
        state => state.app
    );
    const {userLocale, impersonationInfo} = useSelector(state => state.user);
    const {t} = useTranslation();
    const {
        footer_fields: {brand: brand_fields, default: default_fields} = {},
        member_host,
    } = branding || {};
    const activeStatePath = document.location.pathname;
    const dispatch = useDispatch();
    const loggedInMemberId = UserAPI.getMemberId();

    useEffect(() => {
        if (activeStatePath?.includes('condition-management')) {
            dispatch(
                appUpdate({
                    oneAppActiveProgram:
                        FOOTER_CONTENT_STATE.ConditionManagement.id,
                })
            );
        } else if (activeStatePath?.includes('mental-health')) {
            dispatch(
                appUpdate({
                    oneAppActiveProgram: FOOTER_CONTENT_STATE.MyStrength.id,
                })
            );
        } else {
            dispatch(
                appUpdate({
                    oneAppActiveProgram: FOOTER_CONTENT_STATE.TeleMed.id,
                })
            );
        }
    }, [activeStatePath, oneAppActiveProgram, dispatch]);

    const onClickHandler = property => {
        mixpanel.track('footer.button.clicked', {
            button: property,
        });
    };

    const handleSitemapClick = () => {
        onClickHandler('Sitemap');
        dispatch(
            appUpdate({
                oneAppActiveNav: NAV_MENU.Sitemap.id,
            })
        );
    };

    const renderLink = footerField => {
        const {label, key, path, icon} = footerField;

        if (!label || !path) {
            return null;
        }

        if (key === SITEMAP) {
            return (
                <Link
                    key={key}
                    className={css.link}
                    to={path}
                    onClick={handleSitemapClick}
                >
                    {icon && <icon />}
                    {t(label)}
                </Link>
            );
        }

        if (
            (key !== VIDEO_URL && key !== CONSENT_AGREEMENT) ||
            oneAppActiveProgram === FOOTER_CONTENT_STATE.TeleMed.id
        ) {
            return (
                <Link
                    key={key}
                    to={path}
                    target="_blank"
                    className={css.link}
                    onClick={() => onClickHandler(key.split('.').pop())}
                    aria-label={`${t(label)}, ${t(
                        'screenReader.externalWebPage'
                    )}`}
                >
                    {icon}
                    {t(label)}
                </Link>
            );
        }

        return null;
    };

    const generateFooterLinks = fields => {
        const orderedFooterLabels = Object.keys(footerLabels).map(
            (key, index) => {
                return {
                    key,
                    value: footerLabels[key],
                    order: index,
                };
            }
        );

        const labels = Object.keys(fields).map(key => {
            const footerLabel = orderedFooterLabels.find(
                ofl => ofl.key === key
            );

            if (footerLabel) {
                const label = footerLabel.value;
                let path = '';
                let icon;
                const order = footerLabel.order;

                switch (key) {
                    case 'video_url': {
                        path = `${member_host}${fields[key]}`;
                        icon = <IconVideoDefault />;
                        break;
                    }
                    case 'sitemap': {
                        path = getCommonRouteById('sitemap').path;
                        break;
                    }
                    case 'consent_agreement_url': {
                        path = CONSENT_AGREEMENT_URL[userLocale];
                        break;
                    }
                    default: {
                        path = `${fields[key]}?locale=${userLocale}`;
                    }
                }

                return {
                    key,
                    label,
                    path,
                    icon,
                    order,
                };
            } else {
                return {
                    key: '',
                };
            }
        });

        return labels
            .filter(l => Boolean(l.key))
            .sort((a, b) => a.order - b.order)
            .map(renderLink);
    };

    const generateBrandedLinks = fields => {
        if (!Array.isArray(fields)) {
            return [];
        }

        return fields.map(({label, url}) => renderLink({label, path: url}));
    };

    return (
        <footer className={css.root} data-testid="footer-nav-menu">
            <div className={cnb(css.container, css.privateContainer)}>
                <div className={css.footerTitle}>
                    <div className={css.company}>
                        &copy; {branding?.organization}
                    </div>
                    <div className={css.bullet}>&bull;</div>
                    <div className={css.version}>
                        {t('footer.version')} {process.env.VERSION}
                    </div>
                </div>
                {oneAppActiveProgram === FOOTER_CONTENT_STATE.MyStrength.id && (
                    <div className={css.contentMyS}>
                        <p>
                            {t('footer.myStrengthContent')}
                            <a
                                className={css.contact}
                                href="tel:988"
                                aria-label={t('footer.firstPhoneNumber')}
                                onClick={() => onClickHandler('988')}
                            >
                                988
                            </a>
                            &nbsp;
                            {t('footer.or')}
                            &nbsp;
                            <a
                                className={css.contact}
                                href="tel:800-273-8255"
                                aria-label={t('footer.secondPhoneNumber')}
                                onClick={() => onClickHandler('800-273-8255')}
                            >
                                800-273-8255.
                            </a>
                        </p>
                    </div>
                )}
                <div>
                    {default_fields && (
                        <p className={css.links}>
                            {generateFooterLinks(default_fields)}
                        </p>
                    )}
                    {brand_fields && (
                        <p className={css.links}>
                            {generateBrandedLinks(brand_fields)}
                        </p>
                    )}
                </div>
            </div>
            {isImpersonation && (
                <div className={css.impersonationFooter}>
                    <span>
                        Impersonator ID: {impersonationInfo.impersonatorId}
                    </span>
                    <span>
                        Impersonator type: {impersonationInfo.impersonatorType}
                    </span>
                    <span>Impersonated ID: {loggedInMemberId}</span>
                    <span>
                        Impersonated type: {impersonationInfo.impersonatedType}
                    </span>
                </div>
            )}
        </footer>
    );
};

export default Footer;
