import {INBOX_SERVICE_TYPES, NAV_MENU} from '@/config';

const schema = {
    Admin: [
        'allGenericRavRequest',
        'availablePrograms',
        'backCare',
        'carePlan',
        'careTeam',
        'ccmSso',
        'communication',
        'consultCancelId',
        'consultEditId',
        'coverage',
        'doctorsAndPharmacies',
        'documents',
        'emergency',
        'externalProviders',
        'expertMedicalOpinion',
        'family',
        'findBestDoctor',
        'home',
        'medicalHistory',
        'memberHealthRecord',
        'mentalHealth',
        'pharmacy',
        'profilePreferences',
        'security',
        'securityQuestions',
        'sexualHealth',
        'tobaccoCessation',
        'visits',
        INBOX_SERVICE_TYPES.CareTeam,
        NAV_MENU.MessageCenter.id,
    ],
    CallCenterRep: [
        'allGenericRavRequest',
        'availablePrograms',
        'backCare',
        'ccmSso',
        'communication',
        'consultCancelId',
        'consultEditId',
        'coverage',
        'doctorsAndPharmacies',
        'documents',
        'emergency',
        'externalProviders',
        'expertMedicalOpinion',
        'family',
        'findBestDoctor',
        'home',
        'medicalHistory',
        'memberHealthRecord',
        'mentalHealth',
        'pharmacy',
        'profilePreferences',
        'security',
        'securityQuestions',
        'sexualHealth',
        'tobaccoCessation',
        'visits',
    ],
    Member: [],
};

export default schema;
