/* eslint-disable camelcase, no-use-before-define */
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import size from 'lodash/size';
import pick from 'lodash/pick';
import some from 'lodash/some';
import cloneDeep from 'lodash/cloneDeep';
import addMinutes from 'date-fns/addMinutes';
import isBefore from 'date-fns/isBefore';
import Arg from '@livongo/arg';
import StorageUtils from '@livongo/utilities/system/storage';
import APIUtils, {APIRegistry} from '@livongo/utilities/system/api';
import ApptentiveUtils from '../common/apptentive/apptentive-utils';
import {
    MEMBER_ID,
    IMPERSONATION,
    MEMBER_ID_PREV,
    REFERRER_REGEX,
    INITIAL_DATA_LOAD,
    ACCESS_TOKEN_COOKIE,
    REFRESH_TOKEN_COOKIE,
    DATA_LOAD_EXPIRATION,
    CACHED_DATA_MINUTES_TTL,
} from '../config';
import {getStore} from '../store';
import ChatAPI from '../chat/chat-api';
import OneAppMigrationAPI from '../oneAppMigration/oneAppMigration-api';
import ProtoUtils from '../common/utilities/proto-utils';
import programs, {PROGRAM_STATUSES} from '../programs';
import MixpanelUtils from '../common/utilities/mix-panel';
import CommonUtils from '../common/utilities/common-utils';
import {getDomainName} from '../common/utilities/url-utils';
import {initialState as appInitialData} from '../app/app-reducer';
import {userUpdate} from './user-actions';
import {initialState as userInitialData} from './user-reducer';
import UserUtils from './user-utils';

const basicAuthConfig = {
    headers: {
        Authorization: `Basic ${process.env.BASIC_AUTH}`,
        'Content-Type': 'application/x-www-form-urlencoded',
    },
};
const {
    STATUSES,
    Program: {
        ProgramType,
        ProgramStatus,
        DiagnosisType,
        ProgramClient,
        GetProgramsResponse,
    },
    getHeaders,
} = ProtoUtils;

const API_STATUS = [
    {api: 'lang', networkStatus: true},
    {api: 'locale', networkStatus: true},
    {api: 'personalInfo', networkStatus: true},
    {api: 'accountInfo', networkStatus: true},
    {api: 'coachingInfo', networkStatus: true},
    {api: 'featuresConfig', networkStatus: true},
    {api: 'suppliesOrders', networkStatus: true},
    {api: 'webRefresh', networkStatus: true},
    {api: 'partnersPublic', networkStatus: true},
    {api: 'programs', networkStatus: true},
    {api: 'a1cLabsStatus', networkStatus: true},
    {api: 'kidneyLabStatus', networkStatus: true},
    {api: 'launchPad', networkStatus: true},
    {api: 'onboardingDisplay', networkStatus: true},
    {api: 'contacts', networkStatus: true},
];
let currentAccessToken = null;
let timestamp;
let refreshTimeout;

function getHeadersWithUTF8() {
    const apiDefaults = APIRegistry.get('default').instance.defaults;

    return {
        ...apiDefaults.headers,
        'Content-Type': 'application/json; charset=UTF-8',
    };
}

const UserAPI = {
    getSignupUrl({signupUrl, locale, medOptFlavor, newAuthAccessCode} = {}) {
        const language = locale ? `&locale=${locale}` : '';
        const accessCode = newAuthAccessCode
            ? `&registrationAccessCode=${newAuthAccessCode}`
            : '';
        const pbc = medOptFlavor ? `&pbc=WM_DPP` : '';

        return `${signupUrl}/med-opt?referrer=internal${language}${pbc}${accessCode}`;
    },

    getNetworkStatus(value) {
        if (value === 'networkStatus') {
            return (
                API_STATUS.filter(({networkStatus}) => networkStatus)?.length >=
                API_STATUS.length
            );
        }

        if (value === 'tokenInvalid') {
            return (
                API_STATUS.filter(
                    data =>
                        data?.status === 401 &&
                        data?.data?.error === 'invalid_token'
                )?.length > 0
            );
        }
    },

    setCurrentAccessToken(accessToken) {
        currentAccessToken = accessToken;
    },

    getAccessCode() {
        return APIUtils.post('v1/users/me/auth/oAuth2CodeFromJWT', {
            jwt: currentAccessToken,
        }).then(({data}) => data);
    },

    updateAuthorization(response) {
        const {
            data: {
                access_token: accessToken,
                last_activity_timestamp: lastActivityTimestamp,
                refresh_token: refreshToken,
                expires_in: expires,
                type,
            },
        } = response;

        currentAccessToken = accessToken;
        timestamp = lastActivityTimestamp;
        UserUtils.updateHeaders({Authorization: `Bearer ${accessToken}`});

        if (refreshToken) {
            // set up the authorization token refresh to happen 5 minutes before its set to expire
            // the "expires" time comes in seconds from the server so we manipulate it to units we need for setTimeout which is ms
            refreshTimeout = window.setTimeout(() => {
                UserAPI.refreshToken(refreshToken);
            }, expires * 1000 - 300000); // 300,000ms = 5 minutes

            // save the refresh token in a native cookie so that it can be used to check login status across Livongo entities
            StorageUtils.set({
                key: REFRESH_TOKEN_COOKIE,
                value: refreshToken,
                type: 'cookie',
                useNative: true,
                cookieOptions: {
                    domain: getDomainName(),
                    sameSite: 'none',
                    secure: true,
                },
                stringify: false,
            });
        } else {
            StorageUtils.remove({
                key: REFRESH_TOKEN_COOKIE,
                type: 'cookie',
                useNative: true,
            });
        }

        // We don't want to set this cookie for MS Teams
        if (type !== 'teams') {
            // this cookie is needed to properly retrieve food log images because they require this cookie to be present when being loaded from the server
            // we also expose it to other Livongo entities so they can retrieve it and use it for retaining a user's session
            StorageUtils.set({
                key: ACCESS_TOKEN_COOKIE,
                value: accessToken,
                type: 'cookie',
                useNative: true,
                cookieOptions: {
                    domain: getDomainName(),
                    sameSite: 'none',
                    secure: true,
                },
                stringify: false,
            });
        }

        return response;
    },

    // valid grantType: 'password', 'mfa-oob', 'authorization_code
    // 'password' credentials: {username, password}
    // 'mfa-oob' credentials: {bindingCode, mfaToken, oobCode}
    // 'authorization_code' credentials: {code}
    login({grantType = 'password', credentials}) {
        const data = {
            grant_type: grantType,
            ...credentials,
        };

        return APIUtils.post('v1/users/me/auth', data, basicAuthConfig)
            .then(this.updateAuthorization)
            .then(this.redirectIfNeeded)
            .then(this.checkMigrationStatus)
            .then(response => {
                if (!response?.user?.isUserEligibleForOneappMigration) {
                    return this.loadInitialData();
                } else {
                    return response;
                }
            })
            .catch(error => {
                const {mfaToken} = error.data;

                if (mfaToken) {
                    return {user: {...userInitialData, mfaToken}};
                }

                throw error;
            });
    },

    sso({partnerId, accessCode}) {
        const data = {
            access_code: accessCode,
        };

        return APIUtils.post(
            `v1/users/me/partners/${partnerId.toLowerCase()}/auth/sp/code`,
            data,
            basicAuthConfig
        ).then(response => {
            const {
                data: {token, refreshToken, lifeSeconds, ...other},
            } = response;

            return this.updateAuthorization({
                ...response,
                data: {
                    access_token: token,
                    refresh_token: refreshToken,
                    expires_in: lifeSeconds,
                    ...other,
                },
            });
        });
    },

    redirectIfNeeded() {
        const {redirect} = Arg.all();

        // Redirect after login if a 'redirect' URL param is passed
        if (redirect && document.referrer?.match(REFERRER_REGEX)) {
            window.open(redirect, '_self');
        }
    },

    async checkMigrationStatus() {
        const oneAppMigrationResponse =
            await OneAppMigrationAPI.getOneAppMigration();

        const isUserEligibleForOneappMigration = oneAppMigrationResponse
            ?.getMigrationInfo()
            ?.getIsUserEligibleForOneappMigration();

        return {
            user: {...userInitialData, isUserEligibleForOneappMigration},
            app: {...appInitialData},
        };
    },

    getEmailFromRedirect({partnerId = 'myStrength', code}) {
        return APIUtils.post(
            `v1/users/me/partners/${partnerId.toLowerCase()}/auth/idp/code`,
            {code}
        ).then(({data}) => data);
    },

    getMfaDetails(mfaToken) {
        return APIUtils.post('v1/users/auth/mfa/challenges', {mfaToken}).then(
            ({data}) => data
        );
    },

    getMfaContacts(mfaToken) {
        return APIUtils.get(`v1/users/auth/mfa/${mfaToken}/contactPoints`).then(
            ({data}) => data.contactTypes
        );
    },

    sendMfaContact(data) {
        return APIUtils.post('v1/users/auth/mfa/challenges', data);
    },

    logout({oneAppLogout, enrolledPrograms} = {}) {
        if (oneAppLogout) {
            return oneAppLogout(enrolledPrograms);
        } else {
            return APIUtils.post('v1/auth/logout').then(response => {
                window.clearTimeout(refreshTimeout);
                ChatAPI.disconnect();
                UserUtils.logout();
            });
        }
    },

    refreshToken(token) {
        return APIUtils.post(
            'v1/users/me/auth/refresher',
            {
                grant_type: 'refresh_token',
                refresh_token: token,
            },
            basicAuthConfig
        ).then(response => {
            const store = getStore();

            if (!isUndefined(store) && timestamp) {
                store.dispatch(userUpdate({lastActivityTimestamp: timestamp}));
            }

            return this.updateAuthorization(response);
        });
    },

    updatePassword(data) {
        return APIUtils.post('v1/users/me/info/password', data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    },

    requestResetPassword(data) {
        return APIUtils.post('v1/users/me/info/password/tokens', data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
    },

    resetPassword(data) {
        return APIUtils.post('v1/users/me/info/password/reset', data, {
            headers: {
                Authorization: `Token ${Arg('token')}`,
            },
        });
    },

    generateApiStatus(apiName, error) {
        API_STATUS.forEach(item => {
            if (item.api === apiName) {
                item.networkStatus = false;
                item.status = error?.status;
                item.data = error?.data;
            }
        });

        return API_STATUS;
    },

    getPrograms() {
        return ProgramClient.getDiagnosesAndPrograms(
            new GetProgramsResponse(),
            getHeaders()
        )
            .then(data =>
                UserUtils.checkProgramDependentCalls({
                    data,
                    types: ProgramType.Values,
                    statuses: ProgramStatus.Values,
                })
            )
            .catch(error => {
                // ignore permission error so member support can impersonate member accounts
                if (error.code === STATUSES.PERMISSION_DENIED) {
                    return null;
                }

                UserAPI.generateApiStatus('programs', error);

                return error;
            });
    },

    getA1cLabStatus() {
        const status = APIUtils.get(
            `${process.env.SERVICE_URL}/service-athome-labs/users/me/labOrders/results?testType=HbA1C`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${UserAPI.getCurrentAccessToken()}`,
                    'Content-Type': 'application/json',
                },
            }
        )
            .then(data => data.data.length !== 0)
            .catch(err => {
                UserAPI.generateApiStatus('a1cLabsStatus', err);

                return false;
            });

        return status;
    },

    getKidneyLabStatus() {
        const status = APIUtils.get(
            `${process.env.SERVICE_URL}/service-athome-labs/users/me/results`,
            null,
            {
                headers: {
                    Authorization: `Bearer ${UserAPI.getCurrentAccessToken()}`,
                    'Content-Type': 'application/json',
                },
            }
        )
            .then(data => {
                if (data?.data?.CKD?.length) {
                    return true;
                }

                return false;
            })
            .catch(error => {
                UserAPI.generateApiStatus('kidneyLabStatus', error);

                return error;
            });

        return status;
    },

    getLaunchpad() {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-journey-guidance/v1/users/me/launchpad`,
            null
        )
            .then(response => response?.data)
            .catch(err => {
                UserAPI.generateApiStatus('launchPad', err);

                return {};
            });
    },

    onboardingDisplay() {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-journey-guidance/v1/users/me/onboarding/display`
        )
            .then(response => response?.data)
            .catch(err => {
                UserAPI.generateApiStatus('onboardingDisplay', err);

                return {};
            });
    },

    /*
    this method will store app & user redux data based on initial response in local storage.
    */
    storeReduxInLocalStorage(initDataLoad) {
        const futureExpirationDate = addMinutes(
            new Date(),
            CACHED_DATA_MINUTES_TTL
        );

        const deepCopy = cloneDeep(initDataLoad);

        // Hiding PII data
        if (deepCopy?.user) {
            deepCopy.user.address = {};
            deepCopy.user.email = '';
            deepCopy.user.firstName = '';
            deepCopy.user.lastName = '';
            deepCopy.user.fullName = '';
            deepCopy.user.birthDate = '';
            deepCopy.user.phoneNumbers = [];
        }

        StorageUtils.set({
            key: INITIAL_DATA_LOAD,
            value: deepCopy,
            type: 'local',
            useNative: true,
            parse: false,
            stringify: true,
        });
        StorageUtils.set({
            key: DATA_LOAD_EXPIRATION,
            value: futureExpirationDate,
            type: 'local',
            useNative: true,
            parse: false,
            stringify: false,
        });
    },

    /*
        This function sets the initial data for building the CCM pages in a local
        storage variable. The data stored here are non PI/PHI. The CACHED_DATA_MINUTES_TTL variable
        sets the number of minutes the cached/local storage should last. It is set to 5 minutes
        but can be set for any number (> 0).
    */
    loadInitialDataForOneApp({
        response,
        isOneApp,
        isAuthenticated,
        impersonationInfo,
    } = {}) {
        const access_token = response?.access_token;
        const currentDate = new Date();

        const initData = JSON.parse(
            CommonUtils.getLocalStorage(INITIAL_DATA_LOAD)
        );
        const expirationTime =
            CommonUtils.getLocalStorage(DATA_LOAD_EXPIRATION);
        const futureExpirationTime = new Date(expirationTime);

        let impersonationDetails = impersonationInfo?.impersonatorId
            ? impersonationInfo
            : initData
            ? initData?.user?.impersonationInfo
            : {};

        const hasImpersonation = StorageUtils.get({
            key: IMPERSONATION,
            type: 'session',
            useNative: true,
        });

        if (
            hasImpersonation?.impersonatorId &&
            isNil(impersonationDetails?.impersonatorId) &&
            CommonUtils.getLocalStorage(MEMBER_ID_PREV) ===
                CommonUtils.getCookie({key: MEMBER_ID})
        ) {
            impersonationDetails = hasImpersonation;
        }

        if (isOneApp && !access_token) {
            if (
                initData &&
                expirationTime &&
                isBefore(currentDate, futureExpirationTime)
            ) {
                return initData;
            }

            const initDataLoad = {
                app: {
                    isImpersonation: !isNil(
                        impersonationDetails?.impersonatorId
                    ),
                },
                user: {
                    ...userInitialData,
                    isAuthenticated,
                    impersonationInfo: impersonationDetails,
                },
            };

            UserAPI.storeReduxInLocalStorage(initDataLoad);

            return initDataLoad;
        } else if (
            isOneApp &&
            initData &&
            expirationTime &&
            isBefore(currentDate, futureExpirationTime) &&
            initData?.user?.programs?.length > 0
        ) {
            return Promise.all([
                APIUtils.get(
                    'v1/users/me/info/personal?inclIntlPhones=true'
                ).catch(error => {
                    UserAPI.generateApiStatus('personalInfo', error);

                    return Promise.resolve({});
                }),
            ])
                .then(([profileData]) => {
                    const {
                        firstName = '',
                        lastName = '',
                        birthDate = '',
                        email = '',
                        phones = [],
                        address = '',
                    } = profileData?.data ?? {};

                    initData.user.firstName = firstName;
                    initData.user.lastName = lastName;
                    initData.user.email = email;
                    initData.user.birthDate = birthDate;
                    initData.user.fullName = `${firstName} ${lastName}`;
                    initData.user.address = address;
                    initData.user.phoneNumbers =
                        UserUtils.normalizePhones(phones);

                    return initData;
                })
                .catch(error => {
                    UserAPI.generateApiStatus('personalInfo', error);

                    return Promise.resolve([{}]);
                });
        } else {
            return Promise.all([
                APIUtils.get('v1/users/me/prefs/apps/1/1/lang').catch(error => {
                    UserAPI.generateApiStatus('lang', error);

                    return Promise.resolve({});
                }),
                APIUtils.get('v1/users/me/info/locale', {appId: 1}).catch(
                    error => {
                        UserAPI.generateApiStatus('locale', error);

                        return Promise.resolve({});
                    }
                ),
                APIUtils.get(
                    'v1/users/me/info/personal?inclIntlPhones=true'
                ).catch(error => {
                    UserAPI.generateApiStatus('personalInfo', error);

                    return Promise.resolve({});
                }),
                APIUtils.get('v1/accounts/me/info')
                    .then(UserUtils.checkAccountDependentCalls)
                    .catch(error => {
                        UserAPI.generateApiStatus('accountInfo', error);

                        return Promise.resolve({});
                    }),
                APIUtils.get('v1/users/me/coaching/info').catch(error => {
                    UserAPI.generateApiStatus('coachingInfo', error);

                    return Promise.resolve({});
                }),
                APIUtils.get('/v1/users/me/platform/features/config').catch(
                    error => {
                        UserAPI.generateApiStatus('featuresConfig', error);

                        return Promise.resolve({});
                    }
                ),
                // Without a param count, the orders endpoint returns maximum one item
                APIUtils.get('v1/users/me/supplies/orders', {
                    count: 10,
                }).catch(error => {
                    UserAPI.generateApiStatus('suppliesOrders', error);

                    return Promise.resolve({});
                }),
                APIUtils.get('v1/users/me/web/refresh/v2').catch(error => {
                    UserAPI.generateApiStatus('webRefresh', error);

                    return Promise.resolve({});
                }),
                APIUtils.get('v1/users/me/partners/PUBLIC').catch(error => {
                    UserAPI.generateApiStatus('partnersPublic', error);

                    return Promise.resolve({});
                }),
                UserAPI.getPrograms(),
                UserAPI.getA1cLabStatus(),
                UserAPI.getKidneyLabStatus(),
                UserAPI.getLaunchpad(),
                UserAPI.onboardingDisplay(),
            ])
                .then(
                    ([
                        userData,
                        localeData,
                        profileData,
                        accountData,
                        coachingData,
                        featuresData,
                        ordersData,
                        devicesData,
                        userPublicData,
                        programData,
                        a1cLabStatus,
                        kidneyLabStatus,
                        userTasks,
                        onboardingDisplay,
                    ]) => {
                        const {userId = 0} = userData?.data ?? {};
                        const userLocale = localeData?.data?.locale ?? 'en-US';
                        const {
                            firstName = '',
                            lastName = '',
                            birthDate = '',
                            email = '',
                            phones = [],
                            address = '',
                        } = profileData?.data ?? {};
                        const {
                            companyId = '',
                            isInternational = false,
                            addressTypes = ['INDIVIDUAL'],
                            isPOBoxAllowed = false,
                            isDirectToConsumer = false,
                        } = accountData;
                        const {sharedCoaching = false} =
                            coachingData?.data ?? {};
                        const {features = []} = featuresData?.data ?? {};
                        const ordersResponse = ordersData?.data ?? {};
                        const {deviceGroupsByPrograms = []} =
                            devicesData?.data ?? [];
                        const {publicUUID = ''} = userPublicData?.data ?? {};
                        const {
                            diagnoses,
                            activePrograms = [],
                            weightReadings,
                            hasSafeContent,
                            hasFiveDayCheck,
                            bloodPressureStats,
                            hasWeightManagement,
                            hasSetPersonalGoalWeight,
                            programs: userPrograms,
                        } = programData;
                        const fullName = `${firstName} ${lastName}`;
                        const phoneNumbers = UserUtils.normalizePhones(phones);
                        const birthYear = new Date(birthDate).getUTCFullYear();
                        const currentYear = new Date().getUTCFullYear();
                        const yearRange = [];
                        const programNames = [];

                        userPrograms?.forEach(name => {
                            if (name?.status === 'ACTIVE') {
                                programNames.push(name?.program);
                            }
                        });

                        const mixPanelProps = {
                            language: userLocale,
                            'App Type': null,
                        };
                        const hasBehavioralHealth = userPrograms?.some(
                            ({id, status}) =>
                                id === programs?.behavioralHealth?.id &&
                                [
                                    PROGRAM_STATUSES.REGISTERED,
                                    PROGRAM_STATUSES.ACTIVE,
                                ].includes(status)
                        );
                        const hasRegularWeight = weightReadings?.some(
                            ({isIrregular}) => !isIrregular
                        );
                        let i = birthYear;
                        // set default devices for the user so we can set default images on the UI
                        let bloodSugarDevice = 'bg300';
                        let bloodPressureDevice = 'htn';
                        let weightDevice = 'wm1500';
                        const {bloodPressure, bloodSugar, weight} = programs;

                        for (i; i <= currentYear; i++) {
                            yearRange.push({
                                label: String(i),
                                value: String(i),
                            });
                        }

                        // capture program devices being used
                        if (deviceGroupsByPrograms.length) {
                            // hypertension
                            bloodPressureDevice =
                                UserUtils.getDeviceByCategory({
                                    groups: deviceGroupsByPrograms,
                                    categories: bloodPressure.deviceCategories,
                                }) || bloodPressureDevice;

                            if (bloodPressureDevice === 'htnand')
                                // set blood pressure device name to default if it's 'htnand'
                                bloodPressureDevice = 'htn';

                            bloodSugarDevice =
                                UserUtils.getDeviceByCategory({
                                    groups: deviceGroupsByPrograms,
                                    categories: bloodSugar.deviceCategories,
                                }) || bloodSugarDevice;

                            // weight management and prediabetes (DPP)
                            weightDevice =
                                UserUtils.getDeviceByCategory({
                                    groups: deviceGroupsByPrograms,
                                    categories: weight.deviceCategories,
                                }) || weightDevice;
                        }
                        MixpanelUtils.identify(userId);
                        MixpanelUtils.setPeopleProps({
                            Birthdate: birthDate,
                            Language: userLocale,
                            Programs: programNames,
                        });
                        MixpanelUtils.track({
                            event: 'apptentive.clientCode',
                            properties: {
                                clientCode: companyId ?? '',
                                userId,
                            },
                        });

                        MixpanelUtils.setSuperProps(mixPanelProps);

                        const customData =
                            UserUtils.getApptentiveCustomAttribute({
                                userPrograms,
                                userActivePrograms: programNames,
                                clientCode: companyId,
                                userId,
                                isOneApp,
                            });

                        ApptentiveUtils.identify({publicUUID});
                        ApptentiveUtils.updatePerson(customData);
                        ApptentiveUtils.setLocale(userLocale);

                        ProtoUtils.setHeaders({
                            'Accept-Language': userLocale,
                        });

                        const initDataLoad = {
                            // the object app represents attributes more relaled to
                            // the application user experience than attributes of a user account
                            app: {
                                networkUp:
                                    this.getNetworkStatus('networkStatus'),
                                hasSafeContent,
                                hasFiveDayCheck,
                                hasWeightManagement,
                                hasChatAvailable:
                                    !sharedCoaching &&
                                    (!hasBehavioralHealth ||
                                        (hasBehavioralHealth &&
                                            size(activePrograms) > 1)),
                                isScaleOnboardingDone:
                                    hasSetPersonalGoalWeight ||
                                    (!hasWeightManagement && hasRegularWeight),
                                hasKidneyLabResults: kidneyLabStatus,
                                isTokenValid:
                                    this.getNetworkStatus('tokenInvalid'),
                                isImpersonation: !isNil(
                                    impersonationDetails?.impersonatorId
                                ),
                            },
                            user: {
                                isAuthenticated: true,
                                isManuallyLoggedOut: false,
                                userId,
                                publicUUID,
                                companyId,
                                userLocale,
                                firstName,
                                lastName,
                                fullName,
                                birthDate,
                                yearRange: yearRange.reverse(),
                                email,
                                phoneNumbers,
                                address,
                                isInternational,
                                isPOBoxAllowed,
                                addressTypes,
                                isDirectToConsumer,
                                programs: userPrograms,
                                activePrograms,
                                orders: !isEmpty(ordersResponse)
                                    ? ordersResponse
                                    : [],
                                bloodPressureDevice,
                                bloodSugarDevice,
                                weightDevice,
                                hasSetPersonalGoalWeight,
                                hasPersonalizedBPReport: bloodPressureStats
                                    ? bloodPressureStats.challengeStats[0].bps
                                          .length === 5
                                    : null,
                                hasDyslipidemia: some(features, {
                                    name: 'DYSLIPIDEMIA',
                                    state: 'ENABLED',
                                }),
                                // hasKidneyDiseaseDiagnosis is a special UI feature/customization for members from the client Humana under the CKD "campaign".
                                // This is not the same as having a member enrolled under the program (Whole Person) Chronic Kidney Disease.
                                hasKidneyDiseaseDiagnosis: diagnoses
                                    ? diagnoses.some(
                                          diagnosis =>
                                              diagnosis.getType() ===
                                              DiagnosisType.Value.CKD
                                      )
                                    : false,
                                lastActivityTimestamp: timestamp || null,
                                hasLabA1c: a1cLabStatus,
                                userTasks,
                                onboardingDisplay,
                                impersonationInfo: impersonationDetails,
                                sharedCoaching,
                            },
                        }; // end of object

                        UserAPI.storeReduxInLocalStorage(initDataLoad);

                        return initDataLoad;
                    }
                )
                .catch(() => Promise.resolve([{}]));
        }
    },

    loadInitialData({response, isOneApp, isAuthenticated, ...rest} = {}) {
        const isTeams = rest?.data?.type === 'teams';
        const isRedirectFromTeams = rest?.data?.isRedirectFromTeams;

        const access_token = !isTeams
            ? response?.access_token
            : rest?.data?.access_token;

        return isOneApp && !access_token
            ? {user: {...userInitialData, isAuthenticated}}
            : Promise.all([
                  APIUtils.get('v1/users/me/prefs/apps/1/1/lang'),
                  APIUtils.get('v1/users/me/info/locale', {appId: 1}),
                  APIUtils.get('v1/users/me/info/personal?inclIntlPhones=true'),
                  APIUtils.get('v1/accounts/me/info').then(
                      UserUtils.checkAccountDependentCalls
                  ),
                  APIUtils.get('v1/users/me/coaching/info'),
                  APIUtils.get('/v1/users/me/platform/features/config'),
                  // Without a param count, the orders endpoint returns maximum one item
                  APIUtils.get('v1/users/me/supplies/orders', {count: 10}),
                  APIUtils.get('v1/users/me/web/refresh/v2'),
                  APIUtils.get('v1/users/me/partners/PUBLIC'),
                  UserAPI.getPrograms(),
                  UserAPI.getA1cLabStatus(),
                  UserAPI.getKidneyLabStatus(),
                  UserAPI.getLaunchpad(),
                  UserAPI.onboardingDisplay(),
              ]).then(
                  ([
                      userData,
                      localeData,
                      profileData,
                      accountData,
                      coachingData,
                      featuresData,
                      ordersData,
                      devicesData,
                      userPublicData,
                      programData,
                      a1cLabStatus,
                      kidneyLabStatus,
                      userTasks,
                      onboardingDisplay,
                  ]) => {
                      const {
                          data: {userId},
                      } = userData;
                      const {
                          data: {locale: userLocale, uuid: livongoUUID},
                      } = localeData;
                      const {
                          data: {
                              firstName,
                              lastName,
                              birthDate,
                              email,
                              phones,
                              address,
                          },
                      } = profileData;
                      const {
                          companyId,
                          isInternational,
                          addressTypes,
                          isPOBoxAllowed,
                          isDirectToConsumer,
                      } = accountData;

                      const {
                          data: {sharedCoaching},
                      } = coachingData;
                      const {
                          data: {features},
                      } = featuresData;
                      const {data: ordersResponse} = ordersData;
                      const {
                          data: {deviceGroupsByPrograms},
                      } = devicesData;
                      const {
                          data: {publicUUID},
                      } = userPublicData;
                      const {
                          diagnoses,
                          activePrograms,
                          weightReadings,
                          hasSafeContent,
                          hasFiveDayCheck,
                          bloodPressureStats,
                          hasWeightManagement,
                          hasSetPersonalGoalWeight,
                          programs: userPrograms,
                      } = programData;
                      const fullName = `${firstName} ${lastName}`;
                      const phoneNumbers = UserUtils.normalizePhones(phones);
                      const birthYear = new Date(birthDate).getUTCFullYear();
                      const currentYear = new Date().getUTCFullYear();
                      const yearRange = [];
                      const programNames = [];

                      userPrograms?.forEach(name => {
                          if (name?.status === 'ACTIVE') {
                              programNames.push(name?.program);
                          }
                      });

                      const mixPanelProps = {
                          language: userLocale,
                          'App Type': isTeams ? 'MS Teams' : null,
                      };
                      const hasBehavioralHealth = userPrograms?.some(
                          ({id, status}) =>
                              id === programs?.behavioralHealth?.id &&
                              [
                                  PROGRAM_STATUSES.REGISTERED,
                                  PROGRAM_STATUSES.ACTIVE,
                              ].includes(status)
                      );
                      const hasRegularWeight = weightReadings?.some(
                          ({isIrregular}) => !isIrregular
                      );
                      let i = birthYear;
                      // set default devices for the user so we can set default images on the UI
                      let bloodSugarDevice = 'bg300';
                      let bloodPressureDevice = 'htn';
                      let weightDevice = 'wm1500';
                      const {bloodPressure, bloodSugar, weight} = programs;

                      for (i; i <= currentYear; i++) {
                          yearRange.push({
                              label: String(i),
                              value: String(i),
                          });
                      }

                      // capture program devices being used
                      if (deviceGroupsByPrograms.length) {
                          // hypertension
                          bloodPressureDevice =
                              UserUtils.getDeviceByCategory({
                                  groups: deviceGroupsByPrograms,
                                  categories: bloodPressure.deviceCategories,
                              }) || bloodPressureDevice;

                          if (bloodPressureDevice === 'htnand')
                              // set blood pressure device name to default if it's 'htnand'
                              bloodPressureDevice = 'htn';

                          bloodSugarDevice =
                              UserUtils.getDeviceByCategory({
                                  groups: deviceGroupsByPrograms,
                                  categories: bloodSugar.deviceCategories,
                              }) || bloodSugarDevice;

                          // weight management and prediabetes (DPP)
                          weightDevice =
                              UserUtils.getDeviceByCategory({
                                  groups: deviceGroupsByPrograms,
                                  categories: weight.deviceCategories,
                              }) || weightDevice;
                      }

                      MixpanelUtils.identify(userId);
                      MixpanelUtils.setPeopleProps({
                          Birthdate: birthDate,
                          Language: userLocale,
                          Programs: programNames,
                      });
                      MixpanelUtils.track({
                          event: 'apptentive.clientCode',
                          properties: {
                              clientCode: companyId ?? '',
                              userId,
                          },
                      });

                      MixpanelUtils.setSuperProps(mixPanelProps);

                      const customData = UserUtils.getApptentiveCustomAttribute(
                          {
                              userPrograms,
                              userActivePrograms: programNames,
                              clientCode: companyId,
                              userId,
                              isOneApp,
                          }
                      );

                      ApptentiveUtils.identify({publicUUID});
                      ApptentiveUtils.updatePerson(customData);
                      ApptentiveUtils.setLocale(userLocale);

                      ProtoUtils.setHeaders({
                          'Accept-Language': userLocale,
                      });

                      return {
                          // the object app represents attributes more relaled to
                          // the application user experience than attributes of a user account
                          app: {
                              hasSafeContent,
                              hasFiveDayCheck,
                              hasWeightManagement,
                              hasChatAvailable:
                                  !sharedCoaching &&
                                  (!hasBehavioralHealth ||
                                      (hasBehavioralHealth &&
                                          size(activePrograms) > 1)),
                              isScaleOnboardingDone:
                                  hasSetPersonalGoalWeight ||
                                  (!hasWeightManagement && hasRegularWeight),
                              hasKidneyLabResults: kidneyLabStatus,
                              ...(isTeams
                                  ? {
                                        isTeamsEnvironment: isTeams,
                                        isRedirectFromTeams,
                                    }
                                  : null),
                          },
                          user: {
                              isAuthenticated: true,
                              isManuallyLoggedOut: false,
                              userId,
                              publicUUID,
                              companyId,
                              userLocale,
                              livongoUUID,
                              firstName,
                              lastName,
                              fullName,
                              birthDate,
                              yearRange: yearRange.reverse(),
                              email,
                              phoneNumbers,
                              address,
                              isInternational,
                              isPOBoxAllowed,
                              addressTypes,
                              isDirectToConsumer,
                              programs: userPrograms,
                              activePrograms,
                              orders: !isEmpty(ordersResponse)
                                  ? ordersResponse
                                  : [],
                              bloodPressureDevice,
                              bloodSugarDevice,
                              weightDevice,
                              hasSetPersonalGoalWeight,
                              hasPersonalizedBPReport: bloodPressureStats
                                  ? bloodPressureStats.challengeStats[0].bps
                                        .length === 5
                                  : null,
                              hasDyslipidemia: some(features, {
                                  name: 'DYSLIPIDEMIA',
                                  state: 'ENABLED',
                              }),
                              // hasKidneyDiseaseDiagnosis is a special UI feature/customization for members from the client Humana under the CKD "campaign".
                              // This is not the same as having a member enrolled under the program (Whole Person) Chronic Kidney Disease.
                              hasKidneyDiseaseDiagnosis: diagnoses
                                  ? diagnoses.some(
                                        diagnosis =>
                                            diagnosis.getType() ===
                                            DiagnosisType.Value.CKD
                                    )
                                  : false,
                              lastActivityTimestamp: timestamp || null,
                              hasLabA1c: a1cLabStatus,
                              userTasks,
                              onboardingDisplay,
                          },
                      };
                  }
              );
    },

    updateAccountSettings(data) {
        const {
            homeFlag = 'US',
            homeNumber,
            mobileFlag = 'US',
            mobileNumber,
        } = data;
        const mobileNumberData = UserUtils.getNumberData({
            number: mobileNumber,
            countryCode: mobileFlag,
        });
        const homeNumberData = {
            phoneType: 'HO',
            number: null,
            numberE164: null,
            selectedCountry: {
                flag: homeFlag,
            },
        };
        const profileData = {
            ...pick(data, ['firstName', 'lastName', 'birthDate', 'email']),
            address: pick(data, [
                'addressLine1',
                'addressLine2',
                'city',
                'state',
                'zipcode',
            ]),
            phones: [
                {
                    phoneType: 'MO',
                    selectedCountry: {
                        prefix: mobileNumberData.prefix,
                        flag: mobileFlag,
                    },
                    ...(mobileFlag !== 'US'
                        ? {numberE164: mobileNumberData.numberE164}
                        : {number: mobileNumberData.number}),
                },
            ],
        };

        if (homeNumber) {
            const {number, numberE164, prefix} = UserUtils.getNumberData({
                number: homeNumber,
                countryCode: homeFlag,
            });

            homeNumberData.selectedCountry.prefix = prefix;

            if (homeFlag !== 'US') {
                homeNumberData.numberE164 = numberE164;
            } else {
                homeNumberData.number = number;
            }
        }

        profileData.phones.push(homeNumberData);

        return APIUtils.put('v1/users/me/info/personal', profileData).then(
            () => {
                // reformat the phones so we can normalize them for FE consumption
                const phones = profileData.phones.map(
                    ({
                        phoneType,
                        number,
                        numberE164,
                        selectedCountry: {flag},
                    }) => {
                        return {
                            phoneType,
                            countryCode: flag,
                            ...(flag !== 'US' ? {numberE164} : {number}),
                        };
                    }
                );

                return {
                    ...pick(profileData, [
                        'firstName',
                        'lastName',
                        'email',
                        'address',
                    ]),
                    phoneNumbers: UserUtils.normalizePhones(phones),
                };
            }
        );
    },

    updateLanguagePreference(language) {
        return APIUtils.put('v1/users/me/prefs/apps/1/1/lang', {
            appId: 1,
            appInstId: '1',
            prefName: 'lang',
            prefVal: language.slice(0, 2).toUpperCase(),
        });
    },

    getPartnerRedirectData({partnerId, data = null, config}) {
        return APIUtils.post(
            `v1/users/me/partners/${partnerId}/auth/idp/saml/redirect`,
            data,
            config
        )
            .then(response => response.data)
            .catch(error => {
                // error code 110 means an endpoint is reserved for users with writing permission
                // example: support users can't access member's SSO link
                if (error.data && error.data.code !== 110) {
                    throw error;
                }
            });
    },

    getMobileRedirectData({partnerId, data = null, config}) {
        const headers = {
            'x-initial-request-uuid': StorageUtils.get({
                key: 'mobile_sessionUuid',
            }),
        };

        return APIUtils.post(
            `v1/users/partners/${partnerId}/auth/idp/saml/redirect`,
            data,
            {...config, headers}
        )
            .then(response => response.data)
            .catch(error => {
                // error code 110 means an endpoint is reserved for users with writing permission
                // example: support users can't access member's SSO link
                if (error.data && error.data.code !== 110) {
                    throw error;
                }
            });
    },

    getCurrentAccessToken() {
        return currentAccessToken;
    },

    getContacts() {
        return APIUtils.get('v1/users/me/contacts')
            .then(({data}) => data)
            .catch(error => {
                UserAPI.generateApiStatus('contacts', error);

                return error;
            });
    },

    createContact(contact) {
        return APIUtils.post(
            'v1/users/me/contacts',
            UserUtils.formatContactData(contact),
            {
                headers: getHeadersWithUTF8(),
            }
        ).then(({data}) => data);
    },

    updateContact(contact) {
        return APIUtils.patch(
            `v1/users/me/contacts/${contact.id}?contactType=${contact.contactType}`,
            UserUtils.formatContactData(contact),
            {
                headers: getHeadersWithUTF8(),
            }
        ).then(({data}) => data);
    },

    deleteContact(contact) {
        return APIUtils.delete(`v1/users/me/contacts/${contact.id}`);
    },

    updateAlertSettings({settings, contact}) {
        return APIUtils.patch(
            'v1/users/me/settings/alerts/bg',
            UserUtils.formatAlertSettingsData({
                settings,
                contact,
            })
        ).then(({data}) => data);
    },

    associateAccount(ssoAccessCode) {
        return APIUtils.patch(
            `${process.env.SERVICE_URL}/service-sso-member/user/liv/me/v1/externalId?ssoAccessCode=${ssoAccessCode}`,
            null,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        ).catch(() => {
            // silently fail
        });
    },

    hasOpenIdConnect() {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-sso-member/v1/auth/partners/${process.env.TELADOC_PARTNER_ID}/sp/mp/oidc`
        )
            .then(() => true)
            .catch(() => false);
    },
};

export default UserAPI;
