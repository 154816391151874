import {useState, useEffect} from 'react';

export const useLoadedState = data => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const hasData = data !== 'undefined' && Object.keys(data).length > 0;

        if (hasData !== isLoaded) {
            setIsLoaded(hasData);
        }
    }, [data, isLoaded]);

    return isLoaded;
};
