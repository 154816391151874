import isNil from 'lodash/isNil';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import isSameDay from 'date-fns/isSameDay';
import startOfDay from 'date-fns/startOfDay';
import getTime from 'date-fns/getTime';
import ExternalPageLink from '@teladoc/fe-ccm/ui/common/external-page-link/ExternalPageLink';
import {ZENDESK_URL, ONEAPP_PROFILE_SETTINGS_PATH} from '../config';
import {removeTzFromTimestamp} from '../dates';
import MixpanelUtils from '../common/utilities/mix-panel';
import CommonUtils from '../common/utilities/common-utils';

const styles = getComputedStyle(document.documentElement);
const SEVERELY_LOW = 'SEVERELY_LOW'; //
const VERY_LOW = 'VERY_LOW'; // "very low" and "very high" are also caled "very out of range"
const LOW = 'LOW'; // "low" and "high" are also called "out of range"
const IN = 'IN'; // "in" is short for "in range"
const SLIGHTLY_HIGH = 'SLIGHTLY_HIGH';
const HIGH = 'HIGH';
const VERY_HIGH = 'VERY_HIGH';
const SEVERELY_HIGH = 'SEVERELY_HIGH';
const COACHING_URL = process.env.COACHING_URL;
const A1C_VERY_OUT_OF_RANGE = 8;

export const A1C_TARGET = 6.9;
export const MIXPANEL_EVENTS = {
    INFO: 'info',
    TARGET_INFO: 'targetinfo',
    SCHEDULE_A_CALL: 'scheduleacall',
    SEND_A_MESSAGE: 'sendamessage',
    EXPLORE_LIBRARY: 'explorelibrary',
    GET_TIPS_AND_RECIPES: 'gettipsandrecipes',
    MORE_OPTIONS: 'moreoptions',
    OPTIONS: {
        A1C_RESOURCE: 'a1cresource',
        VIEW_ALL_A1C_DATA: 'viewalla1cdata',
        SHARE_HSR: 'sharehsr',
        SCHEDULE_COACHING: 'schedulecoaching',
    },
};
export const A1C_I18N_KEY_VALUES = {
    OUTDATED: 'outdatedTest',
    IN_RANGE: 'withinRange',
    OUT_OF_RANGE: 'outOfRange',
    VERY_OUT_OF_RANGE: 'veryOutOfRange',
};

function getLimitsByMeal({mealCode, ranges: {preMeal, postMeal, other}}) {
    // before meals codes - see BloodSugarUtils.mealTags for reference
    if ([6, 7, 8].includes(mealCode)) {
        return preMeal;
    }

    // after meals codes
    if ([9, 10, 11].includes(mealCode)) {
        return postMeal;
    }

    return other;
}

const BloodSugarUtils = {
    preferenceKeys: {
        BGLowerLimit: 'low',
        BGUpperLimit: 'high',
        prefMeth: 'contactMethod',
        OptIntoNightAlt: 'immediateSupport',
        StartBlTime: 'startTime',
        EndBlTime: 'endTime',
        OptIntoAlerts: 'optInAlerts',
    },

    orderStatusList: {
        ELIGIBLE: 'ELIGIBLE',
        ENOUGH_SUPPLIES: 'ENOUGH_SUPPLIES',
        NOT_ELIGIBLE: 'NOT_ELIGIBLE',
        RECENT_ORDER: 'RECENT_ORDER',
    },

    ranges() {
        // In a specific case the parameter 't' isn't sent becuase we only need the ranges keys values
        return [
            {
                key: VERY_LOW,
                value: -2,
                label: 'common:statusRanges.veryLow',
                pillColor: 'critical',
            },
            {
                key: LOW,
                value: -1,
                label: 'common:statusRanges.low',
                pillColor: 'warning',
            },
            {
                key: IN,
                value: 0,
                label: 'common:statusRanges.in',
                pillColor: 'success',
            },
            {
                key: HIGH,
                value: 1,
                label: 'common:statusRanges.high',
                pillColor: 'warning',
            },
            {
                key: VERY_HIGH,
                value: 2,
                label: 'common:statusRanges.veryHigh',
                pillColor: 'critical',
            },
        ];
    },

    rangesNextGen() {
        return [
            {
                key: SEVERELY_LOW,
                value: -3,
                label: 'common:statusRangesNextGen.severelyLow',
                pillColor: 'critical',
            },
            {
                key: VERY_LOW,
                value: -2,
                label: 'common:statusRangesNextGen.veryLow',
                pillColor: 'critical',
            },
            {
                key: LOW,
                value: -1,
                label: 'common:statusRangesNextGen.low',
                pillColor: 'warning',
            },
            {
                key: IN,
                value: 0,
                label: 'common:statusRangesNextGen.in',
                pillColor: 'success',
            },
            {
                // before meal
                key: SLIGHTLY_HIGH,
                value: 1,
                label: 'common:statusRangesNextGen.slightlyHigh',
                pillColor: 'warning',
            },
            {
                key: HIGH,
                value: 2,
                label: 'common:statusRangesNextGen.high',
                pillColor: 'critical',
            },
            {
                key: VERY_HIGH,
                value: 3,
                label: 'common:statusRangesNextGen.veryHigh',
                pillColor: 'critical',
            },
            {
                key: SEVERELY_HIGH,
                value: 4,
                label: 'common:statusRangesNextGen.severelyHigh',
                pillColor: 'critical',
            },
        ];
    },

    mealTags() {
        return [
            {value: 6, label: 'blood-sugar:mealTags.beforeBreakfast'},
            {value: 9, label: 'blood-sugar:mealTags.afterBreakfast'},
            {value: 7, label: 'blood-sugar:mealTags.beforeLunch'},
            {value: 10, label: 'blood-sugar:mealTags.afterLunch'},
            {value: 8, label: 'blood-sugar:mealTags.beforeDinner'},
            {value: 11, label: 'blood-sugar:mealTags.afterDinner'},
            {value: 12, label: 'blood-sugar:mealTags.snack'},
            {value: 13, label: 'blood-sugar:mealTags.noMeal'},
            {value: -1, label: 'blood-sugar:mealTags.noTag'},
        ];
    },

    feelings() {
        return [
            {value: 9, label: 'blood-sugar:feelings.fine'},
            {value: 10, label: 'blood-sugar:feelings.notFine'},
            {value: 11, label: 'blood-sugar:feelings.lightHeaded'},
            {value: 12, label: 'blood-sugar:feelings.stressed'},
            {value: 13, label: 'blood-sugar:feelings.afterExercise'},
            {value: 14, label: 'blood-sugar:feelings.ateMore'},
            {value: 15, label: 'blood-sugar:feelings.increaseMeds'},
            {value: 16, label: 'blood-sugar:feelings.missedMeds'},
            {value: 17, label: 'blood-sugar:feelings.other'},
        ];
    },

    sources() {
        return [
            {value: 1, label: 'blood-sugar:sources.meter'}, // INTOUCH (Livongometer)
            {value: 2, label: 'blood-sugar:sources.selfReported'}, // MOBILE_APP
            {value: 3, label: 'blood-sugar:sources.meter'}, // MOBILE_APP_BLUETOOTH
            {value: 7, label: 'blood-sugar:sources.selfReported'}, // WEB
            {value: 11, label: 'blood-sugar:sources.simulator'}, // SIMULATOR
            {value: 13, label: 'blood-sugar:sources.meter'}, // BG1000 (Livongometer)
            {value: 14, label: 'blood-sugar:sources.meter'}, // MOBILE_APP_OCR
        ];
    },

    orderStatus(code) {
        const {ELIGIBLE, ENOUGH_SUPPLIES, NOT_ELIGIBLE, RECENT_ORDER} =
            this.orderStatusList;

        const statusList = {
            101: RECENT_ORDER,
            102: RECENT_ORDER,
            103: ENOUGH_SUPPLIES,
            170: NOT_ELIGIBLE,
            171: NOT_ELIGIBLE,
            200: ELIGIBLE,
            default: NOT_ELIGIBLE,
        };

        return statusList[code] || statusList.default;
    },

    getRange({mealCode, reading, ranges}) {
        const limits = mealCode
            ? getLimitsByMeal({
                  mealCode,
                  ranges,
              })
            : ranges.other;

        if (reading < limits.veryLow) {
            return VERY_LOW;
        } else if (reading < limits.low) {
            return LOW;
        } else if (reading > limits.veryHigh) {
            return VERY_HIGH;
        } else if (reading > limits.high) {
            return HIGH;
        } else {
            return IN;
        }
    },

    getRangeNextGen({eatCode: mealCode, value: reading}) {
        const ranges = {
            preMeal: {veryLow: 54, low: 70, high: 130, veryHigh: 250},
            postMeal: {veryLow: 54, low: 70, high: 180, veryHigh: 250},
            other: {veryLow: 54, low: 70, high: 180, veryHigh: 250},
        };
        const limits = mealCode
            ? getLimitsByMeal({
                  mealCode,
                  ranges,
              })
            : ranges.other;

        if (reading < 20) {
            return SEVERELY_LOW;
        } else if (reading < limits.veryLow) {
            return VERY_LOW;
        } else if (reading < limits.low) {
            return LOW;
        } else if (reading > 600) {
            return SEVERELY_HIGH;
        } else if (reading > limits.veryHigh) {
            return VERY_HIGH;
        } else if (reading > limits.high) {
            if ([6, 7, 8].includes(mealCode)) {
                if (reading > limits.high && reading < 180) {
                    return SLIGHTLY_HIGH;
                }
            }

            return HIGH;
        } else {
            return IN;
        }
    },

    getA1cTagContent(reading) {
        const {
            reading: {quantitativeValue},
        } = reading;

        if (quantitativeValue > A1C_TARGET) {
            return {
                variant: 'warning',
                label: 'common:statusRanges.high',
            };
        } else
            return {
                variant: 'success',
                label: 'common:statusRanges.in',
            };
    },

    selectedTags(t, tags) {
        return this.mealTags()
            .filter(({value}) => tags.includes(value.toString()))
            .map(({label}) => t(label));
    },

    filterByTags(data, tags) {
        if (isEmpty(tags)) {
            return data;
        }

        return data.filter(reading => {
            const {eat_code: eatCode = -1} = reading.tags; // "No Tag" doesn't have a value so we assigning -1 to it

            return tags.includes(eatCode.toString());
        });
    },

    filterByDate(data, datetime) {
        const readings = data.filter(({timestamp}) =>
            isSameDay(
                new Date(removeTzFromTimestamp(timestamp)),
                new Date(datetime)
            )
        );

        return orderBy(readings, ['timestamp']).map(item => ({
            ...item,
            timestamp: removeTzFromTimestamp(item.timestamp),
        }));
    },

    formatDataForChart({data, groups, tags = {}}) {
        const filteredData = this.filterByTags(data, tags);
        const series = [];

        if (!isEmpty(data)) {
            forEach(groups, ({id, name, className}) => {
                series.push({
                    name,
                    className,
                    data: filteredData.reduce(
                        (
                            points,
                            {
                                timestamp,
                                value,
                                adaTargetRangeExtendedStatus: status,
                            }
                        ) => {
                            const point = [
                                getTime(
                                    startOfDay(
                                        new Date(
                                            removeTzFromTimestamp(timestamp)
                                        )
                                    )
                                ),
                                value,
                            ];
                            const {key: rangeKey} = this.ranges().find(
                                elem => elem.value === status
                            );

                            // prettier-ignore
                            if (
                                (id === 'inRange' && rangeKey === IN) ||
                                (id === 'outOfRange' && (rangeKey === LOW || rangeKey === HIGH)) ||
                                (id === 'veryOutOfRange' && (rangeKey === VERY_LOW || rangeKey === VERY_HIGH))
                            ) {
                                points.push(point);
                            }

                            return points;
                        },
                        []
                    ),
                    marker: {
                        symbol: id === 'inRange' ? 'circle' : 'square',
                        lineWidth: 3,
                        radius: 4.5,
                        ...(id === 'inRange' && {
                            lineColor: styles.getPropertyValue(
                                '--color-status-success'
                            ),
                            fillColor: styles.getPropertyValue(
                                '--color-status-success'
                            ),
                        }),
                        ...(id === 'outOfRange' && {
                            lineColor: styles.getPropertyValue(
                                '--color-icon-warning'
                            ),
                            fillColor: styles.getPropertyValue(
                                '--color-icon-warning'
                            ),
                        }),
                        ...(id === 'veryOutOfRange' && {
                            lineColor: styles.getPropertyValue(
                                '--color-status-critical'
                            ),
                            fillColor: styles.getPropertyValue(
                                '--color-neutral-background'
                            ),
                        }),
                    },
                });
            });
        }

        return series;
    },

    formatDataForTable({data, tags: selectedTags, t, unit, intlFormat}) {
        return this.filterByTags(data, selectedTags).map(item => {
            const {
                timestamp,
                value,
                tags: {
                    eat_code: eatCode = -1,
                    source: dataSource,
                    OOR_low: low,
                    OOR_high: high,
                }, // "No Tag" doesn't have a value so we're assigning -1 to it
                adaTargetRangeExtendedStatus: status,
            } = item;
            const {label: mealTag} = this.mealTags(t).find(
                elem => elem.value === eatCode
            ) || {label: ''};
            const {label: source} = this.sources(t).find(
                elem => elem.value === dataSource
            ) || {label: ''};
            const range = this.ranges(t).find(elem => elem.value === status);

            return {
                dateTime: intlFormat(
                    new Date(removeTzFromTimestamp(timestamp)),
                    {
                        weekday: 'short',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    }
                ),
                value: `${this.transformLimitValue({
                    value,
                    low,
                    high,
                })} ${unit}`,
                mealTag,
                source,
                range,
            };
        });
    },

    transformLimitValue({value, low, high}) {
        if (isNil(value)) {
            return value;
        }

        if (low || value < 20) {
            return '< 20';
        } else if (high || value > 600) {
            return '> 600';
        } else if (Number.isInteger(value)) {
            return value;
        } else {
            return Math.round(value);
        }
    },
    getA1cDropdownOptions({
        t,
        push,
        path,
        routes,
        params,
        shareHSROnClick,
        source,
        value,
        isOneApp,
    }) {
        const {getSecondaryRoute} = routes;
        const {publicUUID, userLocale} = params;
        const {
            MORE_OPTIONS,
            OPTIONS: {
                A1C_RESOURCE,
                SHARE_HSR,
                VIEW_ALL_A1C_DATA,
                SCHEDULE_COACHING,
            },
        } = MIXPANEL_EVENTS;

        return {
            getGuide: {
                text: `${t('common:dropdownOptions.getGuide')}  
                ${(<ExternalPageLink />)}`,
                onClick: () => {
                    MixpanelUtils.track({
                        event: `${source}.a1c.${MORE_OPTIONS}.${A1C_RESOURCE}.clicked`,
                        properties: {a1cReading: value},
                    });
                    window.open(
                        `${ZENDESK_URL[userLocale]}/articles/6514591815699`
                    );
                },
            },
            shareHSR: {
                text: t('common:dropdownOptions.shareHSR'),
                onClick: () => {
                    MixpanelUtils.track({
                        event: `${source}.a1c.${MORE_OPTIONS}.${SHARE_HSR}.clicked`,
                        properties: {a1cReading: value},
                    });
                    shareHSROnClick();
                },
            },
            viewAllData: {
                text: t('common:dropdownOptions.viewAllData'),
                onClick: () => {
                    MixpanelUtils.track({
                        event: `${source}.a1c.${MORE_OPTIONS}.${VIEW_ALL_A1C_DATA}.clicked`,
                        properties: {a1cReading: value},
                    });
                    push(
                        getSecondaryRoute({
                            primaryId: 'bloodSugar',
                            secondaryId: 'allLogs',
                        }).path,
                        {backToPath: path}
                    );
                },
            },
            scheduleACall: {
                text: `${t('common:dropdownOptions.callACoach')} 
                 ${(<ExternalPageLink />)}`,
                onClick: evt => {
                    MixpanelUtils.track({
                        event: `${source}.a1c.${MORE_OPTIONS}.${SCHEDULE_COACHING}.clicked`,
                        properties: {a1cReading: value},
                    });
                    evt.preventDefault();

                    let oneAppParams;

                    if (isOneApp) {
                        oneAppParams = CommonUtils.generateOneAppParams(
                            ONEAPP_PROFILE_SETTINGS_PATH
                        );
                    }

                    window.open(
                        `${COACHING_URL}?u=${publicUUID}&locale=${userLocale}${
                            isOneApp ? oneAppParams : ''
                        }`
                    );
                },
            },
        };
    },
    getA1cReadingBasedCTAData({
        reading,
        dropdownOptions,
        channels,
        params,
        functions,
        source,
        isOneApp,
    }) {
        const {
            reading: {quantitativeValue},
        } = reading;
        const {getGuide, shareHSR, viewAllData, scheduleACall} =
            dropdownOptions;
        const {publicUUID, userLocale} = params;
        const {dispatch, chatToggle, chatSetCurrentChannel} = functions;
        const {
            SCHEDULE_A_CALL,
            SEND_A_MESSAGE,
            EXPLORE_LIBRARY,
            GET_TIPS_AND_RECIPES,
        } = MIXPANEL_EVENTS;

        if (quantitativeValue >= A1C_VERY_OUT_OF_RANGE) {
            return {
                label: 'veryOutOfRange',
                buttonOnClick: evt => {
                    evt.preventDefault();
                    MixpanelUtils.track({
                        event: `${source}.a1c.${SCHEDULE_A_CALL}.clicked`,
                        properties: {a1cReading: quantitativeValue},
                    });

                    let oneAppParams;

                    if (isOneApp) {
                        oneAppParams = CommonUtils.generateOneAppParams(
                            ONEAPP_PROFILE_SETTINGS_PATH
                        );
                    }

                    window.open(
                        `${COACHING_URL}?u=${publicUUID}&locale=${userLocale}${
                            isOneApp ? oneAppParams : ''
                        }`
                    );
                },
                dropdownContents: [getGuide, shareHSR, viewAllData],
            };
        } else if (quantitativeValue > A1C_TARGET) {
            return {
                label: 'outOfRange',
                buttonOnClick: () => {
                    MixpanelUtils.track({
                        event: `${source}.a1c.${SEND_A_MESSAGE}.clicked`,
                        properties: {a1cReading: quantitativeValue},
                    });
                    dispatch(chatSetCurrentChannel(channels[0]));
                    dispatch(chatToggle());
                },
                dropdownContents: [
                    scheduleACall,
                    getGuide,
                    shareHSR,
                    viewAllData,
                ],
            };
        } else
            return {
                label: 'withinRange',
                buttonOnClick: evt => {
                    evt.preventDefault();
                    MixpanelUtils.track({
                        event: `${source}.a1c.${
                            source === 'bg'
                                ? GET_TIPS_AND_RECIPES
                                : EXPLORE_LIBRARY
                        }.clicked`,
                        properties: {a1cReading: quantitativeValue},
                    });
                    window.open(ZENDESK_URL[userLocale]);
                },
                dropdownContents: [shareHSR, viewAllData],
            };
    },
    formatA1cDataForChart(readings) {
        return [
            {
                data: readings.map(value => ({
                    x: new Date(value.timestamp),
                    y: value.quantitativeValue,
                })),
            },
        ];
    },
    onClickMixpanelTracking({source, type, value}) {
        MixpanelUtils.track({
            event: `${source}.a1c.${type}.clicked`,
            properties: {a1cReading: value},
        });
    },
};

export default BloodSugarUtils;
