import cloneDeep from 'lodash/cloneDeep';
/* eslint-disable node/no-extraneous-import */
import {amplitude, trackScreenEvent} from '@td/websdk';
/* eslint-enable node/no-extraneous-import */
import {SCREEN_TRACK_EVENTS, TRACK_EVENTS_MAP} from '@/config';

const DEFAULT_TRACKING_PROPERTIES = {
    // eslint-disable-next-line camelcase
    page_url: document?.URL ?? '',
};

const initializeAmplitude = () => {
    if (!amplitude.isInitializedAndEnabled()) {
        const amplitudeConfig = {
            client: {
                apiKey: process.env.AMPLITUDE_API_KEY,
                configuration: {minIdLength: 1},
            },
        };

        amplitude.load(amplitudeConfig);
    }
};

/**
 * Base tracking data structure to ensure consistency.
 * The event name and additional properties will be dynamically injected.
 */
const BASE_TRACKING_DATA = {
    initiated: {
        amplitudeEvent: {
            name: '', // Will be dynamically assigned
            properties: {
                ...DEFAULT_TRACKING_PROPERTIES,
            },
        },
    },
};

/**
 * Function to track events dynamically.
 * @param {string} eventKey - The event name key (should exist in TRACK_EVENTS_MAP).
 * @param {Object} props - Additional tracking properties (optional).
 */
const setTrackingData = (eventKey, props = {}) => {
    initializeAmplitude();

    if (!TRACK_EVENTS_MAP[eventKey]) {
        // eslint-disable-next-line no-console
        console.warn(
            `Tracking event "${eventKey}" is not defined in TRACK_EVENTS_MAP.`
        );

        return;
    }

    const trackingData = cloneDeep(BASE_TRACKING_DATA);

    trackingData.initiated.amplitudeEvent.name = TRACK_EVENTS_MAP[eventKey];

    trackingData.initiated.amplitudeEvent.properties = {
        ...DEFAULT_TRACKING_PROPERTIES,
        ...props,
    };

    trackScreenEvent(trackingData, SCREEN_TRACK_EVENTS.Click);
};

const AmpliUtils = {
    setTrackingData,
};

export default AmpliUtils;
