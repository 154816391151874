import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import OneAppUserAPI from '@/user/user-oneapp-api';

export default function useDataTheme() {
    const {branding, isOneApp} = useSelector(state => state.app);
    const bundleIdentifier = OneAppUserAPI.getBundleIdentifier();
    const isPLI = OneAppUserAPI.isPLIBrand();

    useEffect(() => {
        const dataTheme =
            isOneApp && branding?.data_theme
                ? branding.data_theme
                : isPLI
                ? 'pli'
                : 'teladoc';

        if (dataTheme) {
            document.documentElement.setAttribute('data-theme', dataTheme);
        }
    }, [isPLI, branding, isOneApp, bundleIdentifier]);
}
